import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import Logout from "../../components/Logout/Logout";
import EditArticle from "../../components/EditArticle/EditArticle";
import ConfirmDelete from "../../components/ConfirmDelete/ConfirmDelete";
import AddNews from "../../components/AddNews/AddNews";
import "./Home.css";
import SelectRegion from "../../components/SelectRegion/SelectRegion";
const API_BASE_URL = 'https://api.mapmynews.com/public/index.php';

const Home = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchQuery,setSearchQuery]= useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [articleToDelete, setArticleToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [articlesPerPage, setArticlesPerPage] = useState(50);
  const [showAddNews, setShowAddNews] = useState(false);
  const [showRegion, setShowRegion] = useState(false);
  const [apiMessage,setApiMessage] = useState("");
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [fetchingStatus, setFetchingStatus] = useState(null);

  useEffect(() => {
    const fetchSelectedCountries = async () => {
      try {
        const response = await axios.get('https://api.mapmynews.com/fetch_selected_countries.php');
        if (response.data.success && response.data.selectedCountries) {
          setSelectedCountries(response.data.selectedCountries);
        } else {
          console.error('Failed to fetch selected countries:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching selected countries:', error);
      }
    };

    fetchSelectedCountries();
  }, []);
  
  const openModal = (article) => {
    setSelectedArticle(article);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSave = (id, title, description, location, category,subcategory, latitude, longitude, zone, color) => {
    axios.put(API_BASE_URL, { id, title, description,location, category,subcategory, latitude, longitude , zone, color})
      .then(response => {
        // console.log(response.data.message);
        setArticles(prevArticles =>
          prevArticles.map(article =>
            article.id === id ? { ...article, title, description, location, category,subcategory, latitude,longitude, zone, color} : article
          )
        );
      })
      .catch(error => {
        console.error('Error:', error);
        if (error.response) {
          console.error('Response error:', error.response.data);
        } else if (error.request) {
          console.error('Request error:', error.request);
        } else {
          console.error('Setup error:', error.message);
        }
      });
  };

  const isDate = (input) => {
    return /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(input);
  };

  const formatDate = (inputDate) => {
    if (isDate(inputDate)) {
      const [day, month, year] = inputDate.split("/");
      return `${year}-${month}-${day}`;
    } else {
      const date = new Date(inputDate);
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      };
      return date.toLocaleString('en-GB', options).replace(',', ' at');
    }
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    setLoading(true);
    setError(null);
    setFetchingStatus(null);

    let params = {};
    if (isDate(searchQuery)) {
      params.date = formatDate(searchQuery);
    } else {
      params.search = searchQuery;
    }

    try {
      const response = await axios.get(API_BASE_URL, {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Handle API status messages
      if (response.data.status === "fetching") {
        setFetchingStatus("Fetching new articles from API...");
      } else if (response.data.status === "up_to_date") {
        setFetchingStatus(response.data.message);
      }

      // Handle articles data
      if (Array.isArray(response.data)) {
        setArticles(response.data);
      } else if (response.data.articles) {
        setArticles(response.data.articles);
      }

      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setError(error.response?.data?.message || "There was an error fetching the articles");
      setLoading(false);
    }
  };
  
  const handleDelete = (id, title) => {
    setArticleToDelete({ id, title });
    setShowConfirmDelete(true);
  };

  const cancelDelete = () => {
    setShowConfirmDelete(false);
    setArticleToDelete(null);
  };

  const confirmDelete = () => {
    axios.delete(API_BASE_URL, { data: { id: articleToDelete.id } })
      .then(response => {
        // console.log(response.data.message);
        setArticles(prevArticles => prevArticles.filter(article => article.id !== articleToDelete.id));
        setShowConfirmDelete(false);
        setArticleToDelete(null);
      })
      .catch(error => {
        console.error(error);
      });
  };
  const refreshArticles = () => {
    handleSubmit();
  };

  const handleSearchChange = (e) => {
    if (!loading) {
      setSearchQuery(e.target.value);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!loading) {
        handleSubmit();
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleRowsPerPageChange = (event) => {
    setArticlesPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(articles.length / articlesPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="pagination">
        <div className="pagination-rows-per-page">
          Rows per page:
          <select value={articlesPerPage} onChange={handleRowsPerPageChange}>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>

        <div>{`${indexOfFirstArticle + 1}-${indexOfLastArticle} of ${articles.length}`}</div>
        
        <div>
          <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>&lt;</button>
          <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === pageNumbers.length}>&gt;</button>
        </div>
      </div>
    );
  };

  const handleRegion=()=>{
    setShowRegion(true);
  }

  const handleSaveRegions = async (countries) => {
    try {
        const countriestoSave = countries || selectedCountries;
        const countriesParam = countriestoSave.join(',');
        const response = await fetch(`https://api.mapmynews.com/update_countries.php?countries=${encodeURIComponent(countriesParam)}`, {
            method: 'GET'
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(errorText || 'Failed to update countries');
        }
        const data = await response.json();
        if (data.success) {
          setSelectedCountries(countriestoSave); 
          setShowRegion(false);
        } else {
            throw new Error(data.message || 'Unknown error occurred');
        }
      } catch (error) {
        console.error('Error saving countries', error);
        console.log(error.message);
    }
};

  return (
    <div className="home-page">
      <div className="home-page-nav">
        <img src="mmn4 1.png" alt="Logo" />
        <h3>MapmyNews Admin Panel</h3>
        <div className="logout">
          <p>{localStorage.getItem("username")},</p>
          <Logout />
        </div>
      </div>

      <div className="home-page-search">
        <div className="home-page-search-1">
          <button type="submit" onClick={handleRegion} disabled={loading}>Select Region</button>
        </div>
        <div className="home-page-search-2">
          <div className="search">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
            <input 
              type="text" 
              value={searchQuery} 
              onChange={handleSearchChange}
              placeholder="Search" 
              disabled={loading}
              className={loading ? 'input-disabled' : ''}
            />
          </div>
          <button type="submit" onClick={() => setShowAddNews(true)} disabled={loading}>Add News +</button>
        </div>
      </div>

      {loading ? (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p>Loading articles...</p>
        </div>
      ) :(
        <>
      {error && <p className="error-message">{error}</p>}
      {fetchingStatus && <p className="status-message">{fetchingStatus}</p>}
      {apiMessage && <p className="api-message">{apiMessage}</p>}

      {Array.isArray(articles) && articles.length > 0 && (
        <div className="news-articles-div">
          <table>
            <thead>
              <tr className="row">
                <th>Index</th>
                <th>Headline</th>
                <th>Date Published</th>
                <th>Category</th>
                <th>Subcategory</th>
                <th>Location</th>
                <th>Zone</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentArticles.map((article) => (
                <tr key={article.id} className="row">
                  <td style={{ width: "4%" }}>{article.id}</td>
                  <td style={{ width: "35%" }}>{article.title}</td>
                  <td style={{ width: "14%" }}>{formatDate(article.published_at)}</td>
                  <td style={{ width: "8%" }}>{article.category}</td>
                  <td style={{ width: "9%" }}>{article.subcategory}</td>
                  <td style={{ width: "15%" }}>{article.location || ""}</td>
                  <td style={{ width: "10%" }}>{article.zone}</td>
                  <td>
                    <div className="row-action">
                      <FontAwesomeIcon icon={faPen} onClick={() => openModal(article)} className="action" />
                      <FontAwesomeIcon icon={faTrash} onClick={() => handleDelete(article.id, article.title)} style={{ color: "rgba(227, 31, 37)" }} className="action" />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {renderPagination()}
        </>
      )}
      

      {showModal && selectedArticle && (
        <EditArticle
          article={selectedArticle}
          onSave={handleSave}
          onClose={closeModal}
        />
      )}

      {showConfirmDelete && articleToDelete && (
        <ConfirmDelete
          article={articleToDelete}
          onCancel={cancelDelete}
          onConfirm={confirmDelete}
        />
      )}

      {showAddNews && (
        <AddNews onClose={() => setShowAddNews(false)} onSuccess={refreshArticles} />
      )}

      {showRegion && (
        <SelectRegion 
          isOpen={showRegion}
          onClose={() => setShowRegion(false)}
          onSelectCountries={(countries) => {
            setSelectedCountries(countries);
            handleSaveRegions(countries);
          }}
          selectedCountries={selectedCountries}
        />
      )}
      <small style={{textAlign:"center",margin:"2% 0% 1% 0%"}}><strong>© MapmyNews | 2024</strong></small>
    </div>
  );
};

export default Home;