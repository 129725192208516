import React,{useEffect,useRef,useState} from "react";
import './CustomDropdown.css';

const subcategoryIcons = {
    'accident': '/PNG/PNG/accident.png',
    'accident/death': '/PNG/PNG/accident_death.png',
    'air defence': '/PNG/PNG/air defence.png',
    'airforce': '/PNG/PNG/air force.png',
    'business': '/PNG/PNG/business.png',
    'elections': '/PNG/PNG/elections.png',
    'emergency': '/PNG/PNG/emergency.png',
    'entertainment': '/PNG/PNG/entertainment.png',
    'explosion': '/PNG/PNG/explosion.png',
    'finance': '/PNG/PNG/finance.png',
    'financial institution': '/PNG/PNG/financial institution.png',
    'flight': '/PNG/PNG/flight.png',
    'general': '/PNG/PNG/General_ Announcement .png',
    'health': '/PNG/PNG/health.png',
    'legal': '/PNG/PNG/legal.png',
    'military': '/PNG/PNG/military.png',
    'missile': '/PNG/PNG/missile.png',
    'navy': '/PNG/PNG/navy.png',
    'navy ship': '/PNG/PNG/navyship.png',
    'science': '/PNG/PNG/science.png',
    'sports': '/PNG/PNG/sports.png',
    'technology': '/PNG/PNG/technology.png',
    'terror': '/PNG/PNG/terror.png',
    'vessel': '/PNG/PNG/vessel.png',
    'warzone': '/PNG/PNG/warzone.png',
  };


const CustomDropdown = ({ options, value, onChange, disabled }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const dropdownRef = useRef(null);
    const optionsRef = useRef([]);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
  
    const handleKeyDown = (event) => {
      if (disabled) return;
  
      switch (event.key) {
        case 'Enter':
        case ' ':
          if (!isOpen) {
            setIsOpen(true);
            setFocusedIndex(0);
          } else if (focusedIndex !== -1) {
            onChange(options[focusedIndex]);
            setIsOpen(false);
          }
          event.preventDefault();
          break;
        case 'Escape':
          setIsOpen(false);
          break;
        case 'ArrowDown':
          if (!isOpen) {
            setIsOpen(true);
          }
          setFocusedIndex((prevIndex) => 
            prevIndex < options.length - 1 ? prevIndex + 1 : prevIndex
          );
          event.preventDefault();
          break;
        case 'ArrowUp':
          setFocusedIndex((prevIndex) => 
            prevIndex > 0 ? prevIndex - 1 : prevIndex
          );
          event.preventDefault();
          break;
        default:
          break;
      }
    };
  
    useEffect(() => {
      if (isOpen && focusedIndex !== -1) {
        optionsRef.current[focusedIndex]?.focus();
      }
    }, [isOpen, focusedIndex]);
  
    return (
      <div 
        className="custom-dropdown" 
        ref={dropdownRef}
        onKeyDown={handleKeyDown}
      >
        <div 
          className="selected-option" 
          onClick={() => !disabled && setIsOpen(!isOpen)}
          tabIndex={disabled ? -1 : 0}
          role="combobox"
          aria-expanded={isOpen}
          aria-haspopup="listbox"
          aria-controls="dropdown-options"
        >
          {value ? (
            <>
              <img src={subcategoryIcons[value]} alt={`${value} icon`} />
              <span>{value}</span>
            </>
          ) : (
            <span>Select a subcategory</span>
          )}
        </div>
        {isOpen && !disabled && (
          <ul 
            id="dropdown-options"
            className="options" 
            role="listbox"
            aria-activedescendant={focusedIndex !== -1 ? `option-${focusedIndex}` : undefined}
          >
            {options.map((option, index) => (
              <li 
                key={option} 
                onClick={() => {
                  onChange(option);
                  setIsOpen(false);
                }}
                onMouseEnter={() => setFocusedIndex(index)}
                ref={(el) => (optionsRef.current[index] = el)}
                role="option"
                id={`option-${index}`}
                aria-selected={value === option}
                tabIndex={-1}
              >
                <img src={subcategoryIcons[option]} alt={`${option} icon`} />
                <span>{option}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

export default CustomDropdown;